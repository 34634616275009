import React from "react"
import styled from "styled-components"
import ReactPlayer from "react-player"

const VideoContainer = styled.div`
  margin-top: ${props => props.theme.margins.marginLarge};
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
`

const VideoPlayerContainer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <VideoContainer>
    <VideoPlayerContainer url={videoSrcURL} width="100%" height="100%" />
  </VideoContainer>
)
export default Video

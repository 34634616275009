import React from "react"
import styled from "styled-components"

const StyledBigButtonLink = styled.a`
  font-family: ${props => props.theme.fonts.defaultFontItalic};
  font-style: italic;
  display: inline-block;
  width: fit-content;
  margin-right: ${props => props.theme.margins.marginH2};
  margin-bottom: ${props => props.theme.margins.marginH2};
  padding: 0.5em;
  border: 3px solid white;
  font-size: ${props => props.theme.fontSizes.h2};
  transition: all 0.25s;

  &:hover {
    transition: all 0.25s;
    color: ${props => props.theme.colors.invertedText};
    border: 3px solid ${props => props.theme.colors.brandColorBright};
    background-color: ${props => props.theme.colors.brandColorBright};
  }
`

const BigButton = ({ buttonText, buttonURL }) => (
  //   <StyledBigButton>
  <StyledBigButtonLink href={buttonURL} target="_blank">
    {buttonText}
  </StyledBigButtonLink>
  //   </StyledBigButton>
)

export default BigButton

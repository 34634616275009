import React from "react"
import styled from "styled-components"

import Image from "./image"

const StyledImage = styled.img`
  margin-top: ${props => props.theme.margins.marginH1};
`

export default function MarkdownImage({ filename }) {
  return <StyledImage src={filename}></StyledImage>
}

import React from "react"
import styled from "styled-components"

const StyledGifContainer = styled.div`
  width: 25%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: ${props => props.theme.margins.marginLarge};
`

const StyledGif = styled.img`
  color: ${props => props.theme.colors.defaultText};
  width: 100%;
`

export default function MarkdownGif({ filename }) {
  return (
    <StyledGifContainer>
      <StyledGif src={"/gifs/" + filename} />
    </StyledGifContainer>
  )
}

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = ({ filename }) => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile {
        edges {
          node {
            relativePath
            name
            publicURL
            childImageSharp {
              fluid(maxWidth: 1200, maxHeight: 674) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  `)
  const image = data.images.edges.find(n => {
    return n.node.publicURL.includes(filename)
  })
  if (!image) {
    return <div>NO IMAGE</div>
  }
  return <Img fluid={image.node.childImageSharp.fluid} />
}

export default Image

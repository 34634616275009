import React from "react"
import { ThemeProvider } from "styled-components"
import Theme from "./src/themes/theme"
import Shortcodes from "./src/components/games-page-styles"
import { MDXProvider } from "@mdx-js/react"
import "./src/themes/global.css"

export const wrapRootElement = ({ element }) => (
  <>
    <ThemeProvider theme={Theme}>
      <MDXProvider components={Shortcodes}>
        <>{element}</>
      </MDXProvider>
    </ThemeProvider>
  </>
)

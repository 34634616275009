export default {
  colors: {
    bodyBackground: "pink",
    defaultBackground: "black",
    defaultText: "white",
    reducedText: "dimgray",
    invertedBackground: "white",
    invertedText: "black",
    brandColorBright: "#87ffd8",
    brandColorDark: "#442c78",
  },
  fonts: {
    // defaultFont: "Odisea-Regular",
    // defaultFontItalic: "Odisea-RegularItalic",
    // defaultFontBold: "Odisea-Bold",
    // defaultFontBoldItalic: "Odisea-BoldItalic",
    defaultFont: "Odisea",
    defaultFontItalic: "Odisea",
    defaultFontBold: "Odisea",
    defaultFontBoldItalic: "Odisea",
    ghostbutterFont: "HermesRough",
  },
  fontSizes: {
    headerLogo: "2.5em",

    gameTitle: "2.5em",

    h1: "1.75em",
    lineHeightH1: "150%",

    h2: "1.5em",
    lineHeightH2: "150%",

    h3: "1.25em",
    lineHeightH3: "150%",

    p: "1em",
    lineHeightP: "150%",
  },
  margins: {
    marginH1: "0.55em",
    marginH2: "0.45em",
    marginH3: "0.35em",
    marginP: "0.25em",
    marginLarge: "50px",
    marginSlideshow: "26px",
  },
}

import { Link } from "gatsby"
import styled from "styled-components"
import MarkdownImage from "./markdown-image"
import MarkdownGif from "./markdown-gif"
import Video from "./video"
import BigButton from "./big-button"

const Heading1 = styled.h1`
  color: ${props => props.theme.colors.defaultText};
  font-family: ${props => props.theme.fonts.defaultFont};
  font-size: ${props => props.theme.fontSizes.h1};
  margin-bottom: ${props => props.theme.margins.marginH1};
  line-height: ${props => props.theme.fontSizes.lineHeightH2};
  font-family: ${props => props.theme.fonts.defaultFont};
`

const Heading2 = styled.h2`
  color: ${props => props.theme.colors.defaultText};
  font-family: ${props => props.theme.fonts.defaultFont};
  font-size: ${props => props.theme.fontSizes.h2};
  margin-top: ${props => props.theme.margins.marginH3};
  margin-bottom: ${props => props.theme.margins.marginH2};
  line-height: ${props => props.theme.fontSizes.lineHeightH2};
  font-family: ${props => props.theme.fonts.defaultFont};
`

const Heading3 = styled.h3`
  color: ${props => props.theme.colors.defaultText};
  font-family: ${props => props.theme.fonts.defaultFont};
  font-size: ${props => props.theme.fontSizes.h3};
  margin-bottom: ${props => props.theme.margins.marginH3};
  line-height: ${props => props.theme.fontSizes.lineHeightH3};
  font-family: ${props => props.theme.fonts.defaultFont};
`

const Paragraph = styled.p`
  color: ${props => props.theme.colors.defaultText};
  font-family: ${props => props.theme.fonts.defaultFont};
  font-size: ${props => props.theme.fontSizes.p};
  margin-bottom: ${props => props.theme.margins.marginP};
  line-height: ${props => props.theme.fontSizes.lineHeightP};
  font-family: ${props => props.theme.fonts.defaultFont};
`

const ListItem = styled.li`
  list-style: disc;
  list-style-position: inside;
  color: ${props => props.theme.colors.defaultText};
  font-family: ${props => props.theme.fonts.defaultFont};
  font-size: ${props => props.theme.fontSizes.p};
  margin-bottom: ${props => props.theme.margins.marginP};
  line-height: ${props => props.theme.fontSizes.lineHeightP};
  font-family: ${props => props.theme.fonts.defaultFont};
`

const StyledLink = styled(Link)`
  display: inline-block;
  color: ${props => props.theme.colors.defaultText};
  font-family: ${props => props.theme.fonts.defaultFont};
  font-size: ${props => props.theme.fontSizes.p};
  /* margin-top: ${props => props.theme.margins.marginP}; */
  line-height: ${props => props.theme.fontSizes.lineHeightP};
  font-family: ${props => props.theme.fonts.defaultFont};
`

const Shortcodes = {
  Link: StyledLink,
  h1: Heading1,
  h2: Heading2,
  h3: Heading3,
  p: Paragraph,
  li: ListItem,
  GImage: MarkdownImage,
  GGif: MarkdownGif,
  Video,
  BigButton,
} // Provide common components here

export default Shortcodes
